<template>
  <div>
    <v-navigation-drawer
      id="side-panel"
      @mouseout.native="closeSidePanelOnLeave"
      @mouseover.native="cancelCloseSidePanel"
      :absolute="!isPinned"
      class="border-radius elevation-2 mt-2 py-2 side-panel background-translucid"
      floating
      height="calc(100% - 12px)"
      :mini-variant="sidePanelController.isSidePanelHidden"
      mini-variant-width=0
      permanent
      ref="sidePanel"
      right
      stateless
      :width="pannelWidth"
    >
      <div class="d-flex flex-column full-height overflow-hidden">
        <v-toolbar
          dense
          flat
          class="background-translucid"
        >
          <v-spacer />
          <v-btn
            @click="toggleIsPinned"
            fab
            x-small
            class="background-plain mr-4"
            elevation=0
          >
            <icon-tooltip
              :icon-name="isPinned ? 'i.PinOff' : 'i.Pin'"
              :tooltip-text="isPinned ? $t('t.PinOff') : $t('t.Pin')"
            />
          </v-btn>
          <v-btn
            v-if="isPristine && showFullPage"
            @click="sidePanelController.openInFull()"
            fab
            x-small
            class="background-plain mr-4"
            elevation=0
          >
            <icon-tooltip
              :icon-name="'i.StretchToPage'"
              :tooltip-text="$t('t.OpenInFullPage')"
            />
          </v-btn>
          <v-btn
            @click="sidePanelController.hideSidePanel()"
            fab
            x-small
            class="background-plain mr-2"
            elevation=0
          >
            <icon-tooltip
              :icon-name="'i.Collapse'"
              :tooltip-text="$t('t.Collapse')"
            />
          </v-btn>
        </v-toolbar>
        <div
          class="d-flex flex-row ml-6 mr-4 mb-n5"
          v-if="displayedComponent"
        >
          <div class="background-translucid full-width">
            <v-select
              :items="sidePanelController.documentList"
              :item-value="i => ({id: i.id, type: i.type})"
              v-model="selectedDocument"
              dense
            >
              <template v-slot:selection="{ item }">
                <document-picker-list-item-ref
                  class="mb-1"
                  :item="item"
                  :view-type="getTargetDocTy(item)"
                  show-icon
                  show-detail
                  mixed
                  :dense="false"
                />
              </template>
              <template v-slot:item="{ item, on }">
                <v-list-item v-on="on">
                  <v-list-item-content class="my-2">
                    <document-picker-list-item-ref
                      :item="item"
                      :view-type="getTargetDocTy(item)"
                      show-icon
                      show-detail
                      mixed
                      :dense="false"
                    />
                    <!-- <v-list-item-subtitle class="caption font-weight-thin">{{getTargetDocTy(item)}} {{item.diff}}</v-list-item-subtitle> -->
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </div>
          <v-btn
            class="background-plain m-4 ml-4 mr-2"
            fab
            x-small
            elevation=0
            @click="replaceDocument()"
          >
            <icon-tooltip
              :icon-name="'i.Close'"
              :tooltip-text="$t('t.CloseCurrentSidePanel')"
            />
          </v-btn>
        </div>
        <div class="ml-2 mr-2 overflow-y-auto d-flex flex-column side-panel-content">
          <keep-alive>
            <!-- La propriété sidePanelController.isSidePanelHidden sert à éviter la consultation automatique des documents lorsque le sidepanel est masqué (discussion) -->
            <component
              v-if="displayedComponent && !sidePanelController.isSidePanelHidden"
              side-panel
              :key="sidePanelController.displayedDocument.key"
              :is="displayedComponent"
              :document-id="sidePanelController.displayedDocument.id"
              :params="sidePanelController.displayedDocument.params"
              is-side-panel
              @replace-document="replaceDocument($event)"
              @close-document="replaceDocument()"
              @content-is-pristine="isPristine = $event"
              ref="elementList"
            />
          </keep-alive>
        </div>
      </div>
      <div
        v-show="!sidePanelController.isSidePanelHidden && !isPinned"
        class="resize-area"
        draggable
        ref="resizeArea"
        :style="'right: calc('+pannelWidth+'px - 12px);'"
      />
    </v-navigation-drawer>
    <v-btn
      v-if="sidePanelController.displayedDocument && sidePanelController.isSidePanelHidden"
      @click="sidePanelController.toggle()"
      icon
      small
      id="side-panel-open-button"
      class="sidepanel-button pulse-element"
      :class="{'sidepanel-button-bordered': isFramed}"
    >
      <icon-tooltip
        :icon-name="'i.OpenInSidePanel'"
        :tooltip-text="$t('t.OpenInSidePanel')"
      />
    </v-btn>
    <v-dialog
      v-model="sidePanelController.showNewColumnWarning"
      max-width=500
    >
      <v-card v-if="displayedComponent">
        <v-card-title>{{$t('t.Warning')}}</v-card-title>
        <v-card-text>
          {{$t('t.NewDocumentWarning', {value: $t($documentNameFromString(this.displayedDocumentType)).toLowerCase()}) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="sidePanelController.showNewColumnWarning = !sidePanelController.showNewColumnWarning"
          >
            {{$t('t.Ok')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import sidePanelController from '@/sidePanelController'
import themeController from '@/themeController'

export default {
  inheritAttrs: false,
  components: {
    AccountContactSidePanel: () => import('@/pages/account-contacts/account-contact-side-panel'),
    Column: () => import('@/pages/cameleon/columns/column'),
    ColumnSet: () => import('@/pages/search/components/preset/column-set'),
    DocumentPickerListItemRef: () => import('@/components/document-picker-list-item-ref'),
    IconTooltip: () => import('@/components/icon-tooltip'),
    SortSet: () => import('@/pages/search/components/preset/sort-set'),
    TopicSidePanel: () => import('@/pages/topics/topic-side-panel')
  },
  data () {
    return {
      autoHideHandler: undefined,
      isPinned: true,
      isPristine: true,
      isSidePanelHidden: true,
      internalWidth: this.width,
      resizing: false,
      sidePanelController
    }
  },
  provide () {
    return {
      isInSidePanel: true
    }
  },
  computed: {
    displayedComponent () {
      return {
        'account-contacts': 'AccountContactSidePanel',
        columns: 'Column',
        'column-sets': 'ColumnSet',
        'sort-sets': 'SortSet',
        topics: 'TopicSidePanel'
      }[this.sidePanelController?.displayedDocument?.type]
    },
    documentHasId () {
      return !!sidePanelController?.displayedDocument?.id
    },
    displayedDocumentType () {
      return this.selectedDocument?.type
    },
    showFullPage () {
      return this.displayedDocumentType === 'account-contacts' || this.displayedDocumentType === 'topics' || this.displayedDocumentType === 'columns'
    },
    pannelWidth: {
      get () {
        return this.internalWidth
      },
      set (v) {
        this.internalWidth = v
        this.$emit('update:width', this.pannelWidth)
      }
    },
    selectedDocument: {
      get () {
        return sidePanelController?.displayedDocument
      },
      set (n, o) {
        if (n !== o) {
          sidePanelController.displayDocument(n.id, n.type, n.params, true)
        }
      }
    },
    isFramed () {
      return themeController.isFramed
    }
  },
  methods: {
    cancelCloseSidePanel () {
      clearTimeout(this.autoHideHandler)
    },
    closeSidePanelOnLeave (event) {
      if (!this.isPinned && !this.resizing && !this.$refs.sidePanel.$el.contains(event.explicitOriginalTarget)) {
        this.autoHideHandler = setTimeout(sidePanelController.hideSidePanel, 1000)
      }
    },
    replaceDocument ({ id, type, params } = {}) {
      this.$refs.elementList?.$removeFromKeepAliveCache()
      this.$refs.elementList?.$destroy()
      sidePanelController.removeCurrentDocument()

      if (id) {
        sidePanelController.displayDocument(id, type, params, true)
      }

      // Hide side panel if there is no document to display
      if (!sidePanelController.documentList.length) {
        sidePanelController.hideSidePanel()
      }
    },
    dragStartEvent () {
      this.resizing = true
      document.addEventListener('drop', this.dropEvent)
    },
    dropEvent (event) {
      let askedWidth = document.body.clientWidth - event.clientX
      askedWidth = askedWidth < this.width ? this.width : askedWidth
      askedWidth = askedWidth > document.body.clientWidth / 2 ? document.body.clientWidth / 2 : askedWidth
      this.pannelWidth = askedWidth
      document.removeEventListener('drop', this.dropEvent)
      this.resizing = false
    },
    getTargetDocTy (item) {
      return item?.params?.targetDocTy
    },
    resize () {
      this.$nextTick(() => setTimeout(this.$triggerResize, 100))
    },
    toggleIsPinned () {
      this.isPinned = !this.isPinned
      if (this.isPinned) {
        this.pannelWidth = this.width
      }
    }
  },
  mounted () {
    this.$refs.resizeArea.addEventListener('dragstart', this.dragStartEvent)
  },
  beforeDestroy () {
    this.$refs.resizeArea.removeEventListener('dragstart', this.dragStartEvent)
  },
  watch: {
    isPinned () {
      this.resize()
    },
    isSidePanelHidden () {
      this.resize()
    },
    width (v) {
      this.internalWidth = v
    }
  },
  props: {
    width: {
      type: [String, Number],
      default: 256
    }
  }
}
</script>

<style lang="stylus" scoped>
.full-height
  height 100%

.full-width
  width 100%

.resize-area
  cursor col-resize
  height 100%
  position fixed
  top 0
  width 12px

  &:hover
    background-color lightgrey

.side-panel
  border-top-right-radius 0 !important
  border-bottom-left-radius 0 !important
  border-bottom-right-radius 0 !important
  transform none !important
  z-index 10
  transform none

.side-panel-content
  flex 100% 0 1

.sidepanel-button
  position absolute
  right 10px
  bottom -30px
  z-index 10

@css {
.sidepanel-button-bordered {
  color: hsl(0, 0%, var(--primary-lightness)) !important;
}
}
</style>
